import {useSession} from "next-auth/react";
import Link from "next/link";
import {Icon} from "../components/Icon";
import Main from "../components/Main";
import {Role} from "../types/enums";


export default function Index() {
  const { data: session } = useSession();

  return (
    <Main title="Velkommen til OSAIA Care">
      {session?.user.role != Role.Patient && (
        <div className="col-md-12 col-lg-9">
          <p>Via OSAIA Care kan du tilbyde digital, skræddersyet og sundhedsfaglig rådgivning til patienter med osteoporose.</p>
          <p>Herinde vil du være i stand til at kunne bruge siden til at udlevere indhold i form af artikler og videoer til hver af dine patienter.</p>

          <p>Under brugere kan du vælge hvilken patient du gerne vil udlevere indhold
            til. Du kan finde brugerne ved at klikke på drop-down menuen og herefter
            finde deres mail adresse. Det vil være deres unikke identifikation.</p>

          <p>Når du har valgt en bruger kan du vælge hvilke artikler den enkelte bruger skal have udleveret.
            Dette gør du ved at klikke på artikler og vælge dem du gerne vil udlevere. Husk at trykke ”Gem” hver gang at du
            vælger/fravælger nye artikler. </p>

          <p>Hvis du gerne vil åbne og læse en artikel inden du udleverer den til en patient, så klikker du på ”Læs” ved en artikel og så vil den respektive artikel åbne op. </p>

          <p>Skulle du have nogle spørgsmål til programmet, eller hvordan det fungerer, kan du altid finde mere information på <a href="https://osaiaacademy.thinkific.com/courses/vejlederuddannelse" target="_blank" rel="noreferrer">osaia.academy</a> eller ved at skrive på kontakt@osaia.dk</p>
          <Link href="/users" className="btn btn-primary">
            Gå til brugere<Icon name="Users" size="s" />

          </Link>
        </div>
      )}
      {session?.user.role == Role.Patient && (
        <>
          <p>OSAIA Care er en del af DISKOS projektet som har til formål at kunne give dig digital,
            skræddersyet og sundhedsfaglig rådgivning til at hjælpe dig med at leve med osteoporose.</p>
          <p>Herinde vil du være i stand til at kunne finde det indhold i form af artikler og videoer som
            din rådgiver har udleveret til dig.</p>
          <p>Held og lykke</p>
          <Link href="/articles" className="btn btn-primary">
            Se dit indhold<Icon name="FileText" size="s" />

          </Link>
        </>
      )}
    </Main>
  );
}



