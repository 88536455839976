import classNames from "classnames";
import styles from '../styles/Layout.module.scss'

interface Props {
    title?: string | React.ReactNode;
    children: React.ReactNode;
}

export default function Main(props: Props): JSX.Element {
    const { title, children } = props;
    return (
        <div className={classNames(styles.content, "d-flex flex-column")}>
        {title && (
            <div className="border-bottom mb-3 pb-1">
                <h1 className="h3">{title}</h1>
            </div>
        )}
        <div>{children}</div>
    </div>
    )
}
