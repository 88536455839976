import * as icons from "react-feather";
import classNames from 'classnames';
import styles from '../styles/Icon.module.scss';

export type IconName = keyof typeof icons;
export type { Icon as fIcon } from "react-feather";

export type IconProps = {
  /** IconName */
  name: IconName;
  /** Set size of the icon (default md) */
  size?: "s" | "m" | "lg";
  /** Addtional classes */
  className?: string;
} & icons.IconProps;


/** Icon component via react-feather. https://feathericons.com/ **/
export const Icon = ({ name, size, className, ...rest }: IconProps) : JSX.Element => {
    const IconComponent = icons[name];

    const iClass = classNames(className, styles.feather,
    {
      [styles.featherSm]: size === "s",
      [styles.featherLg]: size === "lg",
    });

    return <IconComponent className={iClass} {...rest} />;
}
